import { getMe, doLogout } from '../services/loginService';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


export const Homepage = () => {

    let navigate = useNavigate();

    const [me, setMe] = useState([]);
    const handleLogout = () => {

        doLogout().then(
            navigate('/login')
        )
    }

    useEffect(() => {

        getMe().then(m => setMe(m))
  
    }, [])

    return (
        <>
            <div className='row'>
                <div className='col'>
                        <h1> Homepage</h1>
                        <p> User: {me.user} </p>
                        <Button variant='primary' onClick={() => handleLogout()}> Log Out </Button>
                        <button className='btn btn-primary' onClick={() => handleLogout()}> Log Out</button>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <a href='/stocks'>Stocks</a>
                </div>
            </div>
        </>
    );
  }


