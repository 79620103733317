import axios from "axios";


export const getMe = () => {

    return axios.get('/api/me', {
        headers: {'Content-type': 'application/json'}
    }).then((r) => r.data )

}


export const doLogout = () => {
 
    return axios.get('/api/logout', {
        headers: {'Content-type': 'application/json'}
    }).then((r) => r.data )
    
}

export const doLogin = (password) => {

    return axios.post('/api/login', {
        password: password
    }).then((r) => r.data )

}

