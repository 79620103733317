import React, { Component } from 'react';
import MfnWidget from '../components/mfnFeed';
import {MfnWidget2} from '../components/mfnFeed2';
import InsiderWidget from '../components/insiderFeed';
//import { TwitterTimelineEmbed} from 'react-twitter-embed';


class DsPage extends Component {
    state = {  } 
    render() { 
        return (
            <div className='row'>
                <div className='col'>
                    <MfnWidget2/>
                </div>
                <div className='col'>
                    <InsiderWidget/>
                </div>
                {/* <div className='col'> 
                    <TwitterTimelineEmbed 
                    sourceType="url" 
                    url="https://twitter.com/ContrarianCarl/lists/1402989281262506001?ref_src=twsrc%5Etfw"
                    noHeader
                    options={{height: 800}} />
                </div> */}
            
            </div>
        );
    }
}
 
export default DsPage;