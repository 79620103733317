import React, { Component } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import {highLight} from '../constants';


export class DerivativesPage extends Component {
    constructor(props) {
        super(props)

        this.chartRef = React.createRef();

        this.state = {
            chartOptions : highLight, 
          } 
    }


    
    componentDidMount() {

        var chart = this.chartRef.current.chart.series;
        console.log(chart)


        const getPrice = async() => {
            let p = await fetch('/api/pricehistory')
            let price =  await p.json()
            let priceData = []

            priceData = price.map(row => [new Date(row['trading_day']).getTime(),  row['open'],  row['high'],  row['low'],  row['close']])

            let o = await fetch('/api/option-metrics')
            let options =  await o.json()
            let vixData = []
            let gexData = []

            vixData = options.map(row => [new Date(row['trading_day']).getTime(),  row['vix']])
            gexData = options.map(row => [new Date(row['trading_day']).getTime(),  row['gex']])
            
            
            this.setState({
                chartOptions : {
                    yAxis: [{
                        labels: {
                            align: 'left',
                            x: 10
                        },
                        title: {
                            text: 'OHLC'
                        },
                        lineWidth: 2,
                        resize: {
                            enabled: true
                        },
                        gridLineWidth:0
                    },
                    {
                        opposite: false,
                        labels: {
                            align: 'right',
                            x: -10
                        },
                        title: {
                            text: 'Dx'
                        },
                        lineWidth: 2,
                        resize: {
                            enabled: true
                        },
                        gridLineWidth:0
                    }],
                    series: [{type: 'ohlc', data: priceData, yAxis: 0, name: 'OMXS30', id: 'OMXS30', },
                            {type: 'line', data: vixData, yAxis: 1,  name: 'VIX', id: 'VIX', visible:true},
                            {type: 'line', data: gexData, yAxis: 1,  name: 'GEX', id: 'GEX', visible:false}]
                }
            })
        
        }

        getPrice()


    }

    toggleSeries(idx) {

        if (this.chartRef.current.chart.series[idx].visible) {
            this.chartRef.current.chart.series[idx].hide()
        }else{
            this.chartRef.current.chart.series[idx].show()
        }

    }




    render() { 
        return (<>
        
        <div className='row'>
            <div className='col'>
                <button className='btn btn-primary btn-sm' onClick={() => this.toggleSeries(1)}>Toggle VIX</button>
                <button className='btn btn-primary btn-sm ms-2' onClick={() => this.toggleSeries(2)}>Toggle GEX</button>
            </div>
        </div>
        <div className='row'>
            <div className='col'>
            <HighchartsReact
                        highcharts={Highcharts}
                        constructorType={'stockChart'}
                        options={this.state.chartOptions}
                        ref={this.chartRef}
                        />
            </div>
        </div>
        </>
        );
    }
}
