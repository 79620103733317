import React, {useState, useEffect} from 'react';
import { Modal, Button} from 'react-bootstrap';
import NewsItem from './newsItem.jsx';


export const MfnWidget2 = () => {


    const [newsItems, setNewsItems] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [selectedItem, setSelectedItem] = useState()

    const getNewsItems = () => {
        return fetch('/api/mfn-proxy').then(res => res.json())
    }

    const readNews = (news_id) => { 
        setShowModal(true)
        let curr = newsItems.find(i => i.news_id === news_id)
        setSelectedItem(curr)
    }

    useEffect(() => {
        getNewsItems().then(n => setNewsItems(n['mfn-news']))

        const socket = (timeout) => {

            timeout = timeout || 100;

            const ws = new WebSocket("wss://www.mfn.se/all/s.json");
    
            ws.onmessage = (e) => {
                    let msg = JSON.parse(e.data);
                    setNewsItems(prev => [...prev, msg['items'][0]])
            };
        
            ws.onclose = function (event) {
                timeout = 2*timeout;
                console.log("MFN:", "Closing", event);

                setTimeout(function () {
                    socket(timeout)
                }, timeout);
            };   
        };

        socket(10);

    },[])

    return (<>
        <Modal show={showModal} onHide={() => setShowModal(false)} className="modal-xl">
            <Modal.Header >{selectedItem?.author?.name} {selectedItem?.content?.title}</Modal.Header>
            <Modal.Body> 
                <ul className="list-group list-group-horizontal-md my-1">
                    <a className="list-group-item" href="https://www.avanza.se/placera/pressmeddelanden.html" target="_blank" rel='noreferrer'>Avanza</a>
                    <a className="list-group-item" href="https://www.nordnet.se/marknaden/nyheter"  target="_blank" rel='noreferrer'>Nordnet</a>
                    <a className="list-group-item" href="http://www.nasdaqomxnordic.com/nyheter/foretagsmeddelanden" target="_blank" rel='noreferrer'>Nasdaq</a>
                    <a className="list-group-item" href="https://finanscentralen.fi.se/search/Search.aspx" target="_blank" rel='noreferrer'>FI</a>
                    <a className="list-group-item" href="https://gitlab.modfin.se/modfin/3d-party-integrations/issues" target="_blank" rel='noreferrer'>Gitlab</a>
                    <a className="list-group-item" href="https://admin.mfn.se/admin/monitoring-ng/feed" target="_blank" rel='noreferrer'>DS Dash</a>
                </ul>
                <div dangerouslySetInnerHTML={{'__html': selectedItem?.content?.html ?? ''}}>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='danger' onClick={() => setShowModal(false)}>Close</Button>
            </Modal.Footer>
        </Modal>
        <ol className='list-group shadow-sm'>
        {newsItems.map(news => 
                <NewsItem key={news.news_id} item={news} onClick={() => readNews(news.news_id)}/>
        )}
        </ol>
    </>)
}
