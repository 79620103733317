import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { doLogin } from '../services/loginService';



export const LoginPage = () => {

    const [password, setPassword] = useState(null);
    const [error, setError] = useState(null);

    let navigate = useNavigate();


    const logInUser = async(e) => {
        e.preventDefault();

        doLogin(password).then(r => {
            if (r.ok) {
                navigate('/')
            } else {
                setError('Invalid credentials')
            }
        })


    }
    

    const divStyle = {
        height: '80vh',
    }

    return (
        <>
        <div className="row" style={divStyle}>
            <div className="col-sm-6 m-auto align-middle">
                <h1 className='text-center'>Shortsqueeze.se</h1>
                <form onSubmit={ logInUser } >
                <div className="input-group mb-3">
                    <input className="form-control" 
                            placeholder="Enter password" 
                            id='password' 
                            name="password" 
                            type='password'
                            onChange={(e) => setPassword(e.target.value)}>
                    </input>
                    <button className="btn btn-outline-secondary" 
                            type="sumbit">
                    Login
                    </button>
                </div>
                </form>
                { error && 
                    <div className="alert alert-warning alert-dismissible fade show" role="alert">
                    <strong>Woops!</strong> {error}
                    </div>
                }
                
            </div>
        </div>
        </>
    );
}
