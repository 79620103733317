import React from 'react';


const newsItem = ({item, ...rootDOMAttributes}) => {

  const {news_id, source, content, author, subjects} = item

  let extraClass = ''

  if (author.name === 'Finansinspektionen'){
    extraClass += 'list-group-item-warning'
  }

  if (source === 'mfn'){
    extraClass += 'list-group-item-success'
  }


  return (
    <div {...rootDOMAttributes} key={news_id} className={"list-group-item list-group-item-action flex-column align-items-start " + extraClass}>
        <div className="d-flex w-100 justify-content-between">
            <small>{author.name}</small>
            <small>{new Date(content.publish_date).toLocaleString()}</small>
        </div>
        <p className="mb-1">{content.title}</p>
    </div>
  )
}

export default newsItem