export const yyyymmddDate = (d: Date) => {

    const month = (d.getMonth() + 1 < 10)
        ? '0' + (d.getMonth() + 1)
        : '' + (d.getMonth() + 1)
    const day = (d.getDate() < 10)
        ? '0' + d.getDate()
        : '' + d.getDate()
    const year = d.getFullYear()

    return [year, month, day].join('-');

}