import React, {Component} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCheckSquare } from "@fortawesome/free-solid-svg-icons"


class InsiderWidget extends Component {

    state = { 
        trades:  []    
    } 

    


    componentDidMount() {



        const socket = (timeout) => {

            timeout = timeout || 100;

            const ws = new WebSocket("wss://www.holdings.se/api/kafka/websocket/insider");
   
            ws.onmessage = (e) => {
                 let msg = JSON.parse(e.data);

                 let _trades = [...this.state.trades];
                 
                 if (msg['content']['@type'] === 'InsiderTransaction') {

                    if (msg['content']['fi_transaction_id']) {
                        _trades = _trades.filter(x => x.fi_transaction_id !== msg['content']['fi_transaction_id']) 
                        _trades.push(msg['content'])

                        _trades.sort(function(a,b){
                            return new Date(b.verification_timestamp) - new Date(a.verification_timestamp);
                          });

                    } 

                    this.setState({
                        trades: _trades
                      })  

                }
            };
     
            ws.onclose = function (event) {
                timeout = 2*timeout;
                console.log("WC:", "Closing", event);
                console.log("WC:", "sleeping", timeout, "ms");

                setTimeout(function () {
                    socket(timeout)
                }, timeout);
            };   
        };
    
    socket(10);
     
    };


    displayTrade(data, idx) {
        return (

            <div key={idx} className={"list-group-item list-group-item-action flex-column align-items-start " + (!data.is_checked ? "list-group-item-warning" : "") } >
                <div className="d-flex w-100 justify-content-between">
                    <small> 
                            {data.stock_name} 
                            {data.is_checked ? <FontAwesomeIcon icon={faCheckSquare} /> : ''}
                    </small>
                    <small> {data.is_raw_data ? <FontAwesomeIcon icon={faBell} /> : ''} {new Date(data.verification_timestamp).toLocaleTimeString()}</small>        
                </div>
                <div className="d-flex w-100 justify-content-between">
                    <p className="mb-1">{data.owner_name} ({data.insider_name})</p>
                </div>
                <div className="d-flex w-100 justify-content-between">
                    <p className="mb-1"> {data.transaction_type}</p>
                    <p className="mb-1"> {data.delta_shares} st @ {data.share_price} {data.currency}</p>
                </div>
            </div>

        );
    }
  
    render() { 
        return (    
            <>
            <ol className="list-group">
                {this.state.trades.length > 0 ?
                this.state.trades.map((data, idx) => this.displayTrade(data, idx))
                : <div key='x' className='list-group-item'>No trades yet.</div>
                }              
            </ol>
            </>
        );
    }
}
 
export default InsiderWidget;
