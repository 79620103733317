import axios from "axios";
import { IStock, IAvanzaPriceData, IOptionMetrics } from "../store/interface";

//  export const getStocks = () : Promise<IStock[]> => {
export const getStocks = () => {

    return axios.get('/api/stocks', {
        headers: {'Content-type': 'application/json'}
    }).then((r) => r.data )

}

//  export const getStockByISIN = () : Promise<IStock> => {
export const getStock = (stock_id:number) : Promise<IStock> => {

    return axios.get('/api/stock-info?stock_id='+stock_id, {
        headers: {'Content-type': 'application/json'}
    }).then((r) => r.data )

}


export const getStockPrice = (avanza_id:number): Promise<IAvanzaPriceData[]> => {

    return axios.get('/api/avanza-pricedata?orderbook_id='+avanza_id, {
        headers: {'Content-type': 'application/json'}
    }).then((r) => r.data )

}


export const getOptionMetrics = (ul_id:string): Promise<IOptionMetrics[]> => {
    return axios.get('/api/option-metrics?ul_id='+ul_id, {
        headers: {'Content-type': 'application/json'}
    }).then((r) => r.data )

}
