
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import {Dropdown, Button, Row, Col, Stack, Form } from 'react-bootstrap';
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IStock, IAvanzaPriceData, IOptionMetrics } from "../store/interface";
import { getStock, getStockPrice, getOptionMetrics } from "../services/stocksService";
import { yyyymmddDate } from "../util/dateFormat";
import { formatAmountSpaces } from "../util/numberFormat";
import { highchartsSettings } from "../util/highchartSettings";
import * as Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';



export function StockPage() {

    let { id } = useParams();

    const [stock, setStock] = useState<IStock>()
    const [stockPriceData, setStockPriceData] = useState<IAvanzaPriceData[]>([])
    const [optionMeticsData, setOptionMeticsData] = useState<IOptionMetrics[]>()
    const [stockcChartOptions, setStockChartoptions] = useState<Highcharts.Options>()

    const headerCardStyle = 'text-center border-top border-bottom border-secondary my-2'

    //const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

    const [visibleSeries, setVisibleSeries] = useState<string[]>(['vix'])

    const sidebarCardStyle = 'text-center p-2'
    
    useEffect(() => {
        setStockChartoptions({
            ...highchartsSettings,
            title: {
                text: stock?.ticker ?? ''
            },

            chart: {
                ...highchartsSettings.chart,
                height: '600'
            },
    
            xAxis: [{
                lineWidth: 1,
                type: 'datetime',
                labels: {
                    formatter: function (): string {
                        // @ts-ignore
                        return Highcharts.dateFormat('%Y-%m-%d', this.value);
                    },
                },
                min: new Date().getTime() - 365 * 1 * 24 * 60 * 60 * 1000,
                max: new Date().getTime() + 1 * 24 * 60 * 60 * 1000,

            }],
    
            yAxis: [
            {
                title: { text: stock?.ticker ?? '' },
                resize: {
                    enabled: true
                },
                gridLineWidth:0,
                labels: {
                    align: 'left',
                    x: 10
                },
            },{
                title: { 
                    text: _.chain(visibleSeries)
                            .map(s => _.upperCase(s))
                            .join(', ')
                            .value()
                },
                gridLineWidth:0,
                opposite:false,
                resize: {
                    enabled: true
                }
            }],
    
    
            series: [{
                type: 'ohlc',
                data: stockPriceData ? stockPriceData.map(s => [s.timestamp, s.open, s.high, s.low, s.close]) : [],
                name: stock?.ticker ?? '',
                color: '#606063',
                id: 'ohlc',
                visible: true,
                yAxis: 0,
                showInNavigator: true
            },{
                type: 'line',
                data: optionMeticsData ? optionMeticsData.map(s => [new Date(s.trading_day).getTime(), s.vix]) : [],
                name: 'VIX',
                id: 'vix',
                yAxis: 1,
                visible: visibleSeries.includes('vix'),
                showInNavigator: true
            },{
                type: 'line',
                data: optionMeticsData ? optionMeticsData.map(s => [new Date(s.trading_day).getTime(), s.gex]) : [],
                name: 'GEX',
                id: 'gex',
                yAxis: 1,
                visible: visibleSeries.includes('gex'),
                showInNavigator: true,
            },{
                type: 'column',
                data: optionMeticsData ? optionMeticsData.map(s => [new Date(s.trading_day).getTime(), s.call_volume]) : [],
                name: 'Call Volume',
                id: 'call_volume',
                color: 'green',
                opacity: 0.6,
                yAxis: 1,
                visible: visibleSeries.includes('call_volume'),
                showInNavigator: true,
            },{
                type: 'column',
                data: optionMeticsData ? optionMeticsData.map(s => [new Date(s.trading_day).getTime(), s.put_volume]) : [],
                name: 'Put Volume',
                id: 'put_volume',
                color: 'red',
                opacity: 0.6,
                yAxis: 1,
                visible: visibleSeries.includes('put_volume'),
                showInNavigator: true,
            }]
    }
        )
    }, [visibleSeries, optionMeticsData, stockPriceData, stock])


    const gexScatterChart: Highcharts.Options = {
        ...highchartsSettings,
        title: {
            text: 'GEX vs Returns'
        },
        chart: {
            ...highchartsSettings.chart,
            type: 'scatter'
        },
        navigator: {enabled: false},
        legend:{ enabled:false },
        yAxis:[{
            labels: {
                formatter: function() {
                   return String(parseFloat(this.value as string) * 100) + '%'
                }
              },
        }],
        series: [{
            type: 'scatter',
            name: 'GEX vs Returns',
            data: optionMeticsData?.map(x => {
                const ts = yyyymmddDate(new Date(x.trading_day))
                const y = stockPriceData.find(s => 
                    yyyymmddDate(new Date(s.timestamp)) === ts
                )?.pct_change_next_day
                return [x.gex, y]
            })
        }]
    }

    const vixScatterChart: Highcharts.Options = {
        ...highchartsSettings,
        title: {
            text: 'VIX vs Returns'
        },
        chart: {
            ...highchartsSettings.chart,
            type: 'scatter'
        },
        navigator: {enabled: false},
        legend:{ enabled:false },
        yAxis:[{
            labels: {
                formatter: function() {
                   return String(parseFloat(this.value as string) * 100) + '%'
                }
              },
        }],
        series: [{
            type: 'scatter',
            name: 'VIX vs Returns',
            data: optionMeticsData?.map(x => {
                const ts = yyyymmddDate(new Date(x.trading_day))
                const y = stockPriceData.find(s => 
                    yyyymmddDate(new Date(s.timestamp)) === ts
                )?.pct_change_next_day
                return [x.vix, y]
            })
        }]
    }

    useEffect(() => {
        if (!id) return
        getStock(parseInt(id)).then(setStock)
    },[id])

    useEffect(() => {
        if (!stock?.ava_id) return
        getStockPrice(stock?.ava_id).then(setStockPriceData)
    }, [stock?.ava_id])

    useEffect(() => {
        if (!stock?.nq_id) return
        getOptionMetrics(stock?.nq_id).then(setOptionMeticsData)
    }, [stock?.nq_id])
    
    return (
        <>
        <Row className="bg-light rounded-1 border border-bottom">
            <Col xs={12} lg="auto" className="d-flex align-items-center">
                <h3 className="handwritten" style={{textShadow: '0 0 2px #ff7f0e'}}>{stock?.stock_name}</h3>
            </Col>
            <Col xs={6} lg={1}>
                <Stack gap={1} className={headerCardStyle}>
                    <span className="text-info fs-5">{stock?.ticker}</span>
                    <small className="handwritten-sm">Ticker</small>
                </Stack>
            </Col>
            <Col xs={6} lg={1}>
                <Stack gap={1} className={headerCardStyle}>
                    <span className="text-info fs-5">{stock?.isin}</span>
                    <small className="handwritten-sm">ISIN</small>
                </Stack>
            </Col>
            <Col xs={6} lg={1}>
                <Stack gap={1} className={headerCardStyle}>
                    <span className="text-info fs-5">{stock?.updated_at &&
                    yyyymmddDate(new Date(stock.updated_at))
                    }</span>
                    <small className="handwritten-sm">Last update</small>
                </Stack>
            </Col>
            <Col xs={6} lg={1}>
                <Stack gap={1} className={headerCardStyle}>
                    <span className="text-info fs-5">{stock?.ava_id }</span>
                    <small className="handwritten-sm">Avanza ID</small>
                </Stack>
            </Col>
        </Row>

        <Row>
            <Col lg={8} sm={12} md={12}>

                    <HighchartsReact
                        //immutable={false}
                        highcharts={Highcharts}
                        constructorType={"stockChart"}
                        //allowChartUpdate={true}
                        options={stockcChartOptions}
                        oneToOne={true}
                    />

                 <Dropdown className="d-flex justify-content-end">
                    <Dropdown.Toggle variant="info" size="sm">
                        Chart Settings
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {stockcChartOptions?.series && stockcChartOptions.series
                            .filter(s => s.id !== 'ohlc')
                            .map(s => 
                            <Dropdown.Item key={s.name} onClick={() => {
                                
                                if (visibleSeries.includes(s.id as string)) {
                                    setVisibleSeries(p => visibleSeries.filter(v => v !== s.id as string))
                                } else {
                                    setVisibleSeries(p => [...p, s.id as string])
                                }
                                
                            }}>
                            {s.name}
                            {s.visible && <FontAwesomeIcon className="ms-3" icon={faCheck} /> }
                        </Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>

            </Col>
            <Col xs={12} lg={4}>
                <Row className="m-3 bg-light rounded-2 shadow">
                    <Col xs={6} className="border-end">
                        <Stack className={sidebarCardStyle + ' border-bottom'}>
                            <span className="handwritten">VIX</span>
                            <span className="fs-4 text-info"> {formatAmountSpaces(optionMeticsData?.at(-1)?.vix ?? 0)}</span>
                        </Stack>
                        <Stack className={sidebarCardStyle + ' border-bottom'}>
                            <span className="handwritten">Call Vol</span>
                            <span className="fs-4 text-info"> {formatAmountSpaces(optionMeticsData?.at(-1)?.call_volume ?? 0)}</span>
                        </Stack>
                        <Stack className={sidebarCardStyle}>
                            <span className="handwritten">Call OI</span>
                            <span className="fs-4 text-info"> {formatAmountSpaces(optionMeticsData?.at(-1)?.call_oi ?? 0)}</span>
                        </Stack>
                    </Col>
                    <Col xs={6}>
                        <Stack className={sidebarCardStyle + ' border-bottom'}>
                            <span className="handwritten">GEX</span>
                            <span className="fs-4 text-info"> {formatAmountSpaces(optionMeticsData?.at(-1)?.gex ?? 0)}</span>
                        </Stack>
                        <Stack className={sidebarCardStyle + ' border-bottom'}>
                            <span className="handwritten">Put Vol</span>
                            <span className="fs-4 text-info"> {formatAmountSpaces(optionMeticsData?.at(-1)?.put_volume ?? 0)}</span>
                        </Stack>
                        <Stack className={sidebarCardStyle}>
                            <span className="handwritten">Put OI</span>
                            <span className="fs-4 text-info"> {formatAmountSpaces(optionMeticsData?.at(-1)?.put_oi ?? 0)}</span>
                        </Stack>
                    </Col>
                </Row>
                <Row>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={gexScatterChart}
                        oneToOne={true}
                    />                 
                </Row>
                <Row>
                <HighchartsReact
                        highcharts={Highcharts}
                        options={vixScatterChart}
                        oneToOne={true}
                    />    
                </Row>
            </Col>
        </Row>
        </>
    )
  }
 
