import * as Highcharts from 'highcharts/highstock';


export const highchartsSettings :Highcharts.Options = {
    credits: {
        enabled: false
    },
    colors: ['#ff7f0e', '#1f77b4', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf'],

    title: {
        style: {
            color: '#ddd',
            textTransform: 'uppercase',
            fontSize: '20px'
        }
    },

    chart: {
        animation: false,
  
        style: {
            fontFamily: '\'Ubuntu\', sans-serif'
        },
        plotBorderColor: '#606063',
    },

    yAxis: [{
        labels: {
            align: 'left',
            x: 10
        },
        title: {text: ''},
        lineWidth: 2,
        resize: {
            enabled: true
        },
        gridLineWidth:0
    }],

    rangeSelector: {
        buttons: [{
            type: 'month',
            count: 3,
            text: '3m'
        }, {
            type: 'month',
            count: 6,
            text: '6m'
        }, {
            type: 'year',
            count: 1,
            text: '1y'
        }, {
            type: 'year',
            count: 3,
            text: '3y'
        },{
            type: 'all',
            count: 1,
            text: 'All'
        }],
        inputEnabled: true,
    },
    navigator: {
        enabled: true,
        height: 30
    },

    scrollbar: {
        enabled: false
    },

}