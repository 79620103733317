import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { Homepage } from './pages/homePage';
import { LoginPage } from './pages/loginPage';
import DsPage from './pages/dsPage';
import { DerivativesPage } from './pages/optionsPage';
import { StockPage } from './pages/stockPage';
import StocksPage from './pages/stocksPage';
import RequireAuth from './util/auth';


function App() {

  return (
    <div className="App">
      <div className='container-fluid'>
          <Router>
              <Routes>
                  <Route path="/" element={ <RequireAuth>  <Homepage/>  </RequireAuth> }/> 
                  <Route path='/login' element={<LoginPage/>} /> 
                  <Route path='/dev' element={<p> DEV. </p>} /> 
                  <Route path='/ds-mfn' element={<DsPage/>} /> 
                  <Route path="/hid" element={ <RequireAuth>  <p> SECRET. </p>  </RequireAuth> }/>       
                  <Route path="/chart" element={ <RequireAuth>  <DerivativesPage/>  </RequireAuth> }/>
                  <Route path="/stocks" element={ <RequireAuth>  <StocksPage/>  </RequireAuth> }/> 
                  <Route path="/stock/:id" element={ <RequireAuth>  <StockPage/>  </RequireAuth> }/>                   
              </Routes>
        </Router>
      </div>
    </div>
  );
}


export default App;
