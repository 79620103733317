import React, {useEffect, useState} from 'react';
import {Navigate} from 'react-router-dom';



const RequireAuth = ({ children }: { children: React.ReactNode }): any => {

    const [isAuthenticated, setAuthenticated] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const getAuth = () => {
            fetch('/api/me')
            .then(r => r.json())
            .then(data => {
                console.log(data)
                setAuthenticated(data['auth_ok'])
            })
            .catch((err) => {
                if (err.response.status === 401) {
                  setAuthenticated(false);
                }
            })
            .finally(() => setLoaded(true))
        }

        getAuth()
    }, [])

    if (!loaded) return null;

    if (isAuthenticated) return children;

    return <Navigate to={"/login"}/>;
    

}

export default RequireAuth