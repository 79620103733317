import React, { useEffect, useState, useMemo} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faWindowClose, faEdit} from "@fortawesome/free-solid-svg-icons"
import { Table, Form, InputGroup, Row, Col, Modal, Button } from 'react-bootstrap';
import { getStocks } from "../services/stocksService";
import { IStock } from "../store/interface";
import _ from "lodash";

interface IFilter {
    has_options: boolean,
    text: string,
    sortCol: string,
    ascending: boolean
}

function StocksPage() {

    const [showModal, setShowModal] = useState<boolean>(false);
    const [stocks, setStocks] = useState<IStock[]>([]);
    const [filter, setFilter] = useState<IFilter>({
        has_options: false,
        text: '',
        sortCol: 'stock_name',
        ascending: false
    });   
    const [stockToEdit, setStockToEdit] = useState<IStock>();

    useEffect(() => {

        getStocks().then(s => {
            setStocks(s)
        })

        console.log('renders')

    }, []);


    const onOptionsToggle = (val:boolean) => {
        setFilter({...filter, has_options: val})
    }

    const onTextSearch = (val:string) => {
        console.log('set search to: ', val)
        setFilter({...filter, text: val.toLowerCase()})
    }

    const tableFilter = useMemo(() => {


        let s = _.filter(stocks, i => 
                    ((i.stock_name ?? '').toLowerCase().indexOf(filter.text) > -1 ) && 
                    (i.has_options === filter.has_options || filter.has_options === false)
                )

        switch(filter.sortCol) {

            case 'gex': 
                return _.orderBy(s, (i) => i.gex ?? 0, (filter.ascending ? 'asc' : 'desc'))
            case 'vix':
                return _.orderBy(s, (i) => i.vix ?? 0,  (filter.ascending  ? 'asc' : 'desc') )    
            case 'oi':
                return _.orderBy(s, ({ call_oi, put_oi }) => (call_oi ?? 0) + (put_oi ?? 0) ,  (filter.ascending  ? 'asc' : 'desc') )     
            case 'vol':
                return _.orderBy(s, ({ call_volume, put_volume }) => (call_volume ?? 0) + (put_volume ?? 0) ,  (filter.ascending ? 'asc' : 'desc') )   
            default:
                return _.orderBy(s, ({ gex }) => Math.abs(gex ?? 0) || 0, 'desc')

        }
    }, [stocks, filter.has_options, filter.text, filter.ascending, filter.sortCol])

    const toggleSort = (col:string) => {

        if (col === filter.sortCol) {
            setFilter(p => ({...p, ascending: !p.ascending}))
            
        } else {
            setFilter(p => ({...p, sortCol:col, ascending: false}))        
        }
    }

    const handleUpdate = (stockData:IStock) => {
        setStockToEdit(stockData)
        setShowModal(true)

    };

    const handleSave = () => {
        console.log('Saving stock:', stockToEdit)
        setShowModal(false)
    }
   
    
    return (
        <>  

            <Modal show={showModal} onHide={() => setShowModal(false)} animation={false}>
                <Modal.Header closeButton>
                <Modal.Title>{stockToEdit?.stock_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <InputGroup className="mb-3">
                            <Form.Label className="mx-2">Avanza ID</Form.Label>
                            <Form.Control type="text" value={stockToEdit?.avanza_id} onChange={(e) => setStockToEdit({ ...stockToEdit, avanza_id: parseInt(e.target.value) })}/>
                    </InputGroup>
                    <InputGroup className="mb-3">
                            <Form.Label className="mx-2">Stock Name</Form.Label>
                            <Form.Control type="text" value={stockToEdit?.stock_name} onChange={(e) => setStockToEdit({ ...stockToEdit, stock_name: e.target.value })}/>
                    </InputGroup>

                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={() => handleSave()}>
                    Save Changes
                </Button>
                </Modal.Footer>
            </Modal>


            <Row>
                <Col>
                    <InputGroup className="mb-3">
                            <Form.Label className="mx-2">Options</Form.Label>
                            <Form.Check className="mx-2" type="checkbox" checked={filter.has_options} onChange={(e) => onOptionsToggle(e.target.checked)} />
                            <Form.Control className="mx-2" placeholder="Sök" value={filter.text} onChange={(e) => onTextSearch(e.target.value)} />
                    </InputGroup>
                </Col>
            </Row>

            <Row>
                <Col>

                <Table striped bordered hover size="sm">
                         <thead>
                            <tr>
                                <td>Stock</td>
                                <td>Ticker</td>
                                <td>Venue</td>
                                <td>Options</td>
                                <td onClick={() => toggleSort('vix')}>VIX</td>
                                <td onClick={() => toggleSort('gex')}>GEX</td>
                                <td onClick={() => toggleSort('vol')}>OP. VOL</td>
                                <td onClick={() => toggleSort('oi')}>OP.OI</td>
                                <td>EDIT</td>
                            </tr>                            
                        </thead>
                        <tbody>
                            { stocks && tableFilter.map(x => 
                                    <tr key={x.stock_id}> 
                                        <td><a href={`/stock/${x.stock_id}`}> {x.stock_name} </a></td>
                                        <td> {x.ticker} </td>
                                        <td> {x.venue} </td>
                                        <td> {x.has_options ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faWindowClose} /> }</td>
                                        <td> {x.vix}</td>
                                        <td> {x.gex}</td>
                                        <td> {(x.call_volume ?? 0) + (x.put_volume ?? 0)}</td>
                                        <td> {(x.call_oi ?? 0) + (x.put_oi ?? 0)}</td>
                                        <td onClick={() => handleUpdate(x)}> <FontAwesomeIcon icon={faEdit} /> </td>
                                    </tr>)
                                }
                        </tbody>
                </Table>
                
                </Col>
            </Row>
        </>
    )
  }
 
export default StocksPage;
